<template>
  <div class="totalBody">
    <div class="headerBox">
      <p>包材需求管控</p>
      <div class="currentDate">{{ nowTime }}</div>
    </div>

    <el-row class="rows" :gutter="40">
      <el-col :span="12">
        <el-row>
          <div class="reportBox">
            <h4 class="chartTitle">包材需求</h4>
            <div class="tableBox">
              <el-table :data="dataList1">
                <el-table-column align="center" prop="packMaterialCode" label="包材编码"></el-table-column>
                <el-table-column align="center" prop="itemCnt" label="需求数量"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>

      </el-col>
      <el-col :span="12">
        <el-row>
          <div class="reportBox">
            <h4 class="chartTitle">包材需求</h4>
            <div class="tableBox">
              <el-table :data="dataList2">
                <el-table-column align="center" prop="packMaterialCode" label="包材编码"></el-table-column>
                <el-table-column align="center" prop="itemCnt" label="需求数量"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import {currentDate} from "@/utils/common";

export default {
  name: "PackMaterialBoard",
  data() {
    return {
      nowTime: currentDate(),
      dataList1: [],
      dataList2: [],
    }
  },
  mounted() {
    document.title = '看板'

    setInterval(() => this.nowTime = currentDate(), 1000);

    this.queryPackMaterialDemand()
    setInterval(() => {
      this.queryPackMaterialDemand()
    }, 1000 * 30)

  },
  methods: {
    queryPackMaterialDemand() {
      this.$axios.get('report/queryPackMaterialDemand').then(response => {
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        let arr = res.data || []
        this.dataList1 = arr.splice(0, 17)
        this.dataList2 = arr.splice(0, 17)
      })
    },

  }
}
</script>

<style scoped>
.totalBody {
  color: #ffffff;
  min-width: 1900px;
  min-height: 1080px;
  background: url('../../assets/images/report/bg_report.png') no-repeat;
  background-size: cover; /* 拉伸图片覆盖整个区域 */
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.headerBox {
  width: 100%;
  height: 89px;
  background: url('../../assets/images/report/header.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 30px;
  margin-top: 5px;
}

p {
  margin: 0 0 10px;
  padding-top: 5px;
}

.headerBox > p {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

.headerBox > div {
  position: absolute;
  top: 0;
}

.lineName {
  font-size: 18px;
  right: 200px;
  line-height: 35px;
}

.currentDate {
  font-size: 14px;
  right: 30px;
  line-height: 35px;
}

.rows {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 30px;
}

.chartTitle {
  font-weight: normal;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  color: #01c4f7;
}

.chartTitle:before {
  content: url('../../assets/images/report/title_before.png');
}

.chartTitle:after {
  content: url('../../assets/images/report/title_after.png');
}

.reportBox {
  background: url('../../assets/images/report/report1_bg.png') no-repeat;
  background-size: 100% 100%;
  height: 870px;
}

.tableBox /deep/ .el-table tr {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table th, .el-table tr, .el-table td {
  background-color: transparent;
  border-style: none;
}

.tableBox /deep/ table tbody tr:nth-child(odd) {
  background: rgba(30, 176, 252, .3);
  border: none;
}

.tableBox /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table--enable-row-transition .el-table__body td, .el-table .cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table td, thead {
  color: #1eb0fc;
  font-size: 18px;
}

.tableBox /deep/ .el-table th, thead {
  color: #1eb0fc;
  font-size: 18px;
  padding: 5px 0;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

</style>